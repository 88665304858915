import React from "react";

//Style
import './OneArticle.css'

const OneArticle = ({item}) => {

    return (
        <div className='container__article'>
            <div className='article__description-block'>
                <div>
                    <p className='p3 article__date'>{item.date}</p>
                    <h3 className='article__headline'>{item.headline}</h3>
                    <img src={item.img} loading={'lazy'} alt="img-news" className='article__img article__img-mobile'/>
                    <p className='p3 article__description'>{item.descriptionDeployed}</p>
                </div>
                <div>
                    <p className='p3 article__hash'>{item.hash}</p>
                </div>
            </div>
            <div className='article__block-img'>
                <img src={item.img} loading={'lazy'} alt="img-news" className='article__img article__img-descktop'/>
            </div>
        </div>
    );
}

export default OneArticle;
