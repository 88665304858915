const Language = [{
    EN: {
        aboutUs: 'ABOUT US',
        projects: 'PROJECTS',
        vacancies: 'VACANCIES',
        news: 'NEWS',
    },
    UA: {
        aboutUs: 'ПРО НАС',
        projects: 'ПРОЄКТИ',
        vacancies: 'ВАКАНСІЇ',
        news: 'НОВИНИ',
    },
}]
export default Language;