import {useEffect, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import Language from "./Language";
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import './VacanciesAccordion.css'

const VacanciesAccordion = ({stateLanguage}) => {
    let {hash} = useLocation();
    const [languages] = Language;
    const [expanded, setExpanded] = useState('#panel1');

    useEffect(() => {
        setExpanded(hash)
    }, [hash])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(panel);
    };

    return (
        <div className="body-page__vacancies-accordion">
            {languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
                    <Accordion expanded={expanded === ('#panel' + (key + 1))}
                               onChange={handleChange('#panel' + (key + 1))} key={key}>
                        <Link to={{hash: ('#panel' + (key + 1))}}>
                            <AccordionSummary
                                expandIcon={expanded === ('#panel' + (key + 1)) ? <RemoveIcon/> : <AddIcon/>}
                                aria-controls={`panel${key + 1}d-content`}
                                id={`panel${key + 1}d-header`}
                            >
                                <h5 className='headline__list-main'>{item.headline}</h5>
                            </AccordionSummary>
                        </Link>

                        <AccordionDetails>
                            <div className={'body__list-all'}>
                                <div className='list-all__left'>
                                    <p
                                        className='p1 headline__list'>{languages[stateLanguage] && languages[stateLanguage].headlineRespos}</p>
                                    <ul className='list-vacan'>
                                        {item.responsibilities.map((itemList, keyList) => (
                                                <li
                                                    className='list-vacan__item' key={keyList}>{!Array.isArray[itemList] ?
                                                    <p>{itemList}</p> : itemList.map((itemListInside, keyListInside) => (
                                                        <li className='list-vacan__item-inside'
                                                            key={keyListInside}>{itemListInside}</li>
                                                    ))
                                                }
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    {item.plus && <>
                                        <p
                                            className='p1 headline__list headline__list-plus '>{languages[stateLanguage] && languages[stateLanguage].headlinePlus}</p>
                                        <p className='p2 description__list-plus'>{item.plus}</p>
                                    </>}
                                </div>
                                <div className='list-all__right'>
                                    <p
                                        className='p1 headline__list'>{languages[stateLanguage] && languages[stateLanguage].headlineSkills}</p>
                                    <ul className='list-vacan'>
                                        {item.skill.map((itemList, keyList) => {
                                            return (
                                                <li className='list-vacan__item' key={keyList}>{itemList}</li>
                                            )
                                        })}
                                    </ul>
                                    {item.active ? <Link to='/form' className='link-vacancies'>
                                            <button
                                                className={'button-opacity button-vacancies ' + (item.active ? '' : 'button-opacity__disabled')}>{item.button}</button>
                                        </Link> :
                                        <button
                                            className={'button-opacity button-vacancies ' + (item.active ? '' : 'button-opacity__disabled')}>{item.button}</button>}
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                )
            )}
        </div>
    );
}

export default VacanciesAccordion;
