import aboutUsImg from "../../Images/background_about-us.jpg";

const Language = [{
    EN: [{
        aboutUsHeadline: 'Is a subsidiary of NPC «Ukrenergo»',
        headline: 'Ukrenergo Digital Solutions',
        description: ['We believe that successful reforms require reliable, modern and convenient tools. That is why we develop innovative software products for the energy sector of Ukraine.',
            'Our goal is to provide NEC Ukrenergo with the tools necessary to implement its strategic goals. And for the next 10 years we have a lot of work.',
        'We create products that no one has made before, so we:',
            ['Flexible and ready to change',
            'We appreciate the experience of each team member',
            'We work with modern technologies'],
            'The tools we create are a guarantee of successful completion of the electricity market reform in Ukraine. Join us and your knowledge and intelligence will create products that will change the country.'],
        documentsBtn: 'Documents',
        img: aboutUsImg,
    }],
    UA: [{
        aboutUsHeadline: 'Дочірня Компанія НЕК «Укренерго»',
        headline: 'Ukrenergo Digital Solutions',
        description: ['Ми віримо, що для успішних реформ потрібні надійні, сучасні та зручні інструменти. Тому ми розробляємо інноваційні програмні продукти для енергетичної галузі України.',
            'Наша мета — забезпечити НЕК «Укренерго» інструментами, необхідними для реалізації стратегічних цілей. І на наступні 10 років у нас багато роботи.',
            'Ми створюємо продукти, які до нас ще ніхто не робив, тому ми:',
            ['Гнучкі і готові змінюватися','Цінуємо досвід кожного учасника команди','Працюємо з сучасними технологіями'],
            'Інструменти, які ми створюємо — гарантія успішного завершення реформи ринку електроенергії в Україні. Приєднуйтесь до нас і ваші знання та розум створюватимуть продукти, які змінять країну. '],
        documentsBtn: 'Документи',
        img: aboutUsImg,
    }]
}]
export default Language;
