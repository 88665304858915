import FormComponent from "../Components/FormComponent/FormComponent";

const Projects = ({stateLanguage}) => (
	<section className="body-page__projects main-style__section">
		<div className="container">
			<FormComponent stateLanguage={stateLanguage}/>
		</div>
	</section>
)

export default Projects;
