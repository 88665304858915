const Language = [{
    EN: {
        items: [
            {
                headline: 'We are changing energy.',
                headline2: 'We are changing Ukraine.',
                description: ['We understand the needs of the modern energy market and are able to create reliable and convenient tools to meet them.','With our products, Ukraine will become part of the European market, develop a competitive and transparent market in Ukraine and even be able to reduce electricity prices for consumers.',''],
                button: 'ABOUT US',

            }
        ]
    },
    UA: {
        items: [
            {
                headline: 'Змінюємо енергетику.',
                headline2: 'Змінюємо Україну.',
                description: ['Ми розуміємо потреби сучасного енергетичного ринку і вміємо створювати надійні та зручні інструменти для їхнього забезпечення.','З нашими продуктами Україна стане частиною європейського ринку, розвиватиме конкурентний і прозорий ринок в Україні та навіть зможе знизити ціни на електроенергію для споживачів.'],
                button: 'ПРО НАС',

            }
        ]
    }
}]
export default Language;