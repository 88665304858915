import VacanciesAccordion from '../Components/VacanciesAccordion/VacanciesAccordion'
import Language from "./Language";

const Vacancies = ({stateLanguage}) => {
	const [languages] = Language;

	return (
		<section className="body-page__vacancies">
			<div className="container">
				<h1
					className='line-after__white main-style__headline'>{languages[stateLanguage] && languages[stateLanguage].vacancies}</h1>
				<VacanciesAccordion stateLanguage={stateLanguage}/>
			</div>
		</section>
	);
}

export default Vacancies;
