import  ImgNews1 from '../../Images/imgOneNews.jpg'
import  ImgNews2 from '../../Images/imgOneNews2.jpg'

const Language = [{
    EN: {
        crumbsNews: 'NEWS',
        crumbsArticle: 'ARTICLE',
        items: [
            {
                id: 1,
                date: '04.04.2021',
                headline: 'Укренерго запрошує виробників з ВДЕ на вебінар з питань компенсації за невідпущену електроенергію',
                description: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію».',
                hash: '#Балансуючий ринок #ВДЕ #Загальні новини #Оголошення #Події #Ринок електроенергії #Робочі групи',
                img: ImgNews1,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 2,
                date: '02.04.2021',
                headline: 'Корегування граничних значень імпорту електроенергії на 5-11 квітня 2021 року',
                description: 'До уваги учасників ринку, відповідно до скоригованих НЕК «Укренерго» прогнозних балансів потужності на 5-11 квітня 2021 року, публікуються граничні значення імпорту електроенергії до торгової зони «ОЕС Україн …',
                hash: '#Експорт/імпорт #Загальні новини #Ринок електроенергії #Робота ОЕС',
                img: ImgNews2,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 3,
                date: '04.04.2021',
                headline: 'Укренерго запрошує виробників з ВДЕ на вебінар з питань компенсації за невідпущену електроенергію',
                description: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію».',
                hash: '#Балансуючий ринок #ВДЕ #Загальні новини #Оголошення #Події #Ринок електроенергії #Робочі групи',
                img: ImgNews1,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 4,
                date: '02.04.2021',
                headline: 'Корегування граничних значень імпорту електроенергії на 5-11 квітня 2021 року',
                description: 'До уваги учасників ринку, відповідно до скоригованих НЕК «Укренерго» прогнозних балансів потужності на 5-11 квітня 2021 року, публікуються граничні значення імпорту електроенергії до торгової зони «ОЕС Україн …',
                hash: '#Експорт/імпорт #Загальні новини #Ринок електроенергії #Робота ОЕС',
                img: ImgNews2,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
        ]
    },
    UA: {
        crumbsNews: 'НОВИНИ',
        crumbsArticle: 'СТАТТЯ',
        items: [
            {
                id: 1,
                date: '04.04.2021',
                headline: 'Укренерго запрошує виробників з ВДЕ на вебінар з питань компенсації за невідпущену електроенергію',
                description: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію».',
                hash: '#Балансуючий ринок #ВДЕ #Загальні новини #Оголошення #Події #Ринок електроенергії #Робочі групи',
                img: ImgNews1,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 2,
                date: '02.04.2021',
                headline: 'Корегування граничних значень імпорту електроенергії на 5-11 квітня 2021 року',
                description: 'До уваги учасників ринку, відповідно до скоригованих НЕК «Укренерго» прогнозних балансів потужності на 5-11 квітня 2021 року, публікуються граничні значення імпорту електроенергії до торгової зони «ОЕС Україн …',
                hash: '#Експорт/імпорт #Загальні новини #Ринок електроенергії #Робота ОЕС',
                img: ImgNews2,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 3,
                date: '04.04.2021',
                headline: 'Укренерго запрошує виробників з ВДЕ на вебінар з питань компенсації за невідпущену електроенергію',
                description: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію».',
                hash: '#Балансуючий ринок #ВДЕ #Загальні новини #Оголошення #Події #Ринок електроенергії #Робочі групи',
                img: ImgNews1,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
            {
                id: 4,
                date: '02.04.2021',
                headline: 'Корегування граничних значень імпорту електроенергії на 5-11 квітня 2021 року',
                description: 'До уваги учасників ринку, відповідно до скоригованих НЕК «Укренерго» прогнозних балансів потужності на 5-11 квітня 2021 року, публікуються граничні значення імпорту електроенергії до торгової зони «ОЕС Україн …',
                hash: '#Експорт/імпорт #Загальні новини #Ринок електроенергії #Робота ОЕС',
                img: ImgNews2,
                descriptionDeployed: 'НЕК «Укренерго» 8 квітня 2021 року проведе для виробників електроенергії з ВДЕ вебінар «Керування обмеженнями ВДЕ. Як реалізувати право на компенсацію за невідпущену електроенергію». Але щоб ви зрозуміли, звідки виникає це хибне уявлення людей, цуратись насолоди і вихваляти страждання, я розкрию перед вами всю картину і роз’ясню, що саме говорив цей чоловік, який відкрив істину, якого я б назвав зодчим щасливого життя. '
            },
        ]
    }
}]
export default Language;