//Modules
import {useEffect} from "react";
import {Route, Switch, useLocation, Redirect} from "react-router-dom";

//Pages
import AboutUs from '../Pages/AboutUs'
import Projects from '../Pages/Projects'
import Vacancies from '../Pages/Vacancies'
import MainPage from "../Pages/MainPage";
import FormPage from "../Pages/FormPage";
import News from "../Pages/News";
import OneNewsPage from "../Pages/OneNewsPage";
import DocumentsPage from "../Pages/DocumentsPage";

const RootRouter = ({stateLanguage}) => {
	const {pathname} = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return (
		<Switch>
			<Route path="/form"><FormPage stateLanguage={stateLanguage}/></Route>
			<Route path="/news"><News stateLanguage={stateLanguage}/></Route>
			<Route path="/article"><OneNewsPage stateLanguage={stateLanguage}/></Route>
			<Route path="/about-us"><AboutUs stateLanguage={stateLanguage}/></Route>
			<Route path="/vacancies"><Vacancies stateLanguage={stateLanguage}/> </Route>
			<Route path="/projects"><Projects stateLanguage={stateLanguage}/> </Route>
			<Route path="/documents"><DocumentsPage stateLanguage={stateLanguage}/> </Route>
			<Route exact path="/"> <MainPage stateLanguage={stateLanguage}/></Route>
			<Redirect to={'/'}/>
		</Switch>
	)
}

export default RootRouter;
