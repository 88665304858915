import {NavLink} from "react-router-dom";
import Language from './Language'
import {ReactComponent as LogoFooter} from '../../Images/logoFooter.svg'
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import RoomOutlinedIcon from '@material-ui/icons/RoomOutlined';
import './Footer.css';

const Footer = ({stateLanguage, changeLanguage}) => {
	const [languages] = Language;

	return (
		<footer className="footer">
			<div className='all-footer'>
				<div className="container">
					<div className='top-footer'>
						<div className='footer_left-part'>
							<NavLink to="/"><LogoFooter/></NavLink>
							<div className='footer_all-links'>
								<ul className='footer_links'>
									<li className='main-item item-menu'>
										<NavLink activeClassName='item-menu__link-active' className='item-menu__link'
														 to="/about-us">{languages[stateLanguage]?.aboutUs}</NavLink>
									</li>
									<li className='main-item item-menu'>
										<NavLink activeClassName='item-menu__link-active' className='item-menu__link'
														 to="/projects">{languages[stateLanguage]?.projects}</NavLink>
									</li>
									<li className='main-item item-menu'>
										<NavLink activeClassName='item-menu__link-active' className='item-menu__link'
														 to="/vacancies#panel1">{languages[stateLanguage]?.vacancies}</NavLink>
									</li>
									{/*<li className='main-item item-menu'>*/}
									{/*    <NavLink activeClassName='item-menu__link-active' className='item-menu__link' to="/news">{languages[stateLanguage]?.news}</NavLink>*/}
									{/*</li>*/}
								</ul>
								<ul className='main-list list-language__items'>
									<li className={'main-item item-language ' + (stateLanguage === 'UA' ? 'active-language' : '')}
											onClick={() => changeLanguage('UA')}>UA
									</li>
									<li className={'main-item item-language ' + (stateLanguage === 'EN' ? 'active-language' : '')}
											onClick={() => changeLanguage('EN')}>EN
									</li>
								</ul>
							</div>
						</div>
						<div className='footer_right-part'>
							<a href='https://www.linkedin.com/company/ukrenergo-digital-solutions/' className='linkedin-link'
								 target="_blank" rel="noreferrer">
								<LinkedInIcon/>
							</a>
							<ul className='list-contact'>
								<li className='contact-item'>
									<RoomOutlinedIcon/>
									<a href='https://www.google.com/maps/place/%D1%83%D0%BB.+%D0%A1%D0%B8%D0%BC%D0%BE%D0%BD%D0%B0+%D0%9F%D0%B5%D1%82%D0%BB%D1%8E%D1%80%D1%8B,+27,+%D0%9A%D0%B8%D0%B5%D0%B2,+02000/@50.4425592,30.4927901,17z/data=!3m1!4b1!4m5!3m4!1s0x40d4cef3a64cf74f:0xe38f2b5891a8c2f6!8m2!3d50.4425592!4d30.4949788' target='blank'><span>{languages[stateLanguage]?.street}</span></a>
								</li>
								<li className='contact-item'>
									<PhoneOutlinedIcon/>
									<a href='tel:+380442491800'>+38 (044) 249-18-00</a>
								</li>
								<li className='contact-item'>
									<MailOutlineOutlinedIcon/>
									<a href='mailto:official@uds.energy'>official@uds.energy</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className='bottom-footer'>
				<div className='container'>
					<p className='bot-p'>© {new Date().getFullYear()} UDS ALL RIGHTS RESERVED</p>
				</div>
			</div>
		</footer>
	);
}

export default Footer;
