//Modules
import React from "react";

//Components
import ProjectItem from './ProjectItem'
import Language from "./Language";

//Swiper
import {Swiper, SwiperSlide} from "swiper/react";
import SwiperCore, {
    Pagination,
} from 'swiper/core';

//css
import './OurProjects.css';

SwiperCore.use([Pagination]);

const OurProjects = ({stateLanguage}) => {
    const [languages] = Language;

    return (
        <section className='body__our-projects'>
            <div className='container'>
                    <h2 className='our-projects__main-headline line-after__blue'>{languages[stateLanguage] && languages[stateLanguage].mainHeadline}</h2>
                <div className='container__our-projects'>
                    <Swiper
                        breakpoints={{
                        720: {
                            slidesPerView: 2,
                        },
                    }} spaceBetween={16}
                        pagination={{
                        clickable: true
                    }}>
                        {languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
                            <SwiperSlide key={key}>
                                <ProjectItem item={item} key={key}/>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </div>
        </section>
    );
}

export default OurProjects;
