const Language = [{
    EN: {
        mainHeadline: 'Want to join?',
        mainDescription: 'Working with us is a real opportunity to change the country. Your knowledge, intelligence and experience will create products that lead us into the future.',
    },
    UA: {
        mainHeadline: 'Хочете приєднатися?',
        mainDescription: 'Робота з нами — це справжня можливість змінювати країну. Ваші знання, розум та досвід створюватимуть продукти, які ведуть нас в майбутнє.',
    }
}]
export default Language;