import React from "react";

//Components
import OneNews from './OneNews'
import Language from "./Language";

//Style
import './NewsPage.css'


const NewsPage = ({stateLanguage}) => {
    const [languages] = Language;

    return (
        <div className="body-page__news">
            {languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
                <OneNews item={item} key={key}/>
            ))}
        </div>
    );
}

export default NewsPage;
