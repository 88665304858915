import React from "react";

const OfferItem = ({item}) => {

    return (
            <div className='body__offer-item'>
                <h4 className='offer-item__headline'>{item.headline}</h4>
                <p className='offer-item__description p2 '>{item.description}</p>
            </div>
    );
}

export default OfferItem;