import NewsPage from "../Components/NewsPage/NewsPage";
import Language from "./Language";

const News = ({stateLanguage}) => {
	const [languages] = Language;

	return (
		<section className="body-page__news main-style__section">
			<div className="container">
				<h1
					className='line-after__white main-style__headline'>{languages[stateLanguage] && languages[stateLanguage].news}</h1>
				<NewsPage stateLanguage={stateLanguage}/>
			</div>
		</section>
	);
}

export default News;
