import React, {useState} from "react";
import Language from "./Language";
import TextField from "@material-ui/core/TextField";
import FormHelperText from '@material-ui/core/FormHelperText';

const currencies = [
	{
		value: '#',
		label: '',
	},
	{
		value: 'Devops engineer',
		label: 'Devops engineer',
	},
	{
		value: 'Business analyst',
		label: 'Business analyst',
	},
	{
		value: 'Python Developer',
		label: 'Python Developer',
	},
	{
		value: 'QA engineer',
		label: 'QA engineer',
	},
	{
		value: 'Team lead',
		label: 'Team lead',
	},
];

const FormComponent = ({stateLanguage}) => {
	const [languages] = Language;
	const [selectValue, setSelectValue] = useState('');
	const [nameValue, setNameValue] = useState('');
	const [numberValue, setNumberValue] = useState('');
	const [textValue, setTextValue] = useState('');

	return (
		<form className='container-form__right-block'>
			<h4
				className='container-form__right-block__headline'>{languages[stateLanguage] && languages[stateLanguage].headlineForm}</h4>
			<div className='form__inputs'>
				<TextField id="standard-basic"
									 helperText={languages[stateLanguage] && languages[stateLanguage].form1}
									 value={nameValue}
									 onChange={(e) => setNameValue(e.target.value)}/>
				<div className='form__select-body'>
					<select
						value={selectValue}
						onChange={(e) => setSelectValue(e.target.value)}
						className='select-body'
					>
						{currencies.map((option) => (
							<option key={option.value} className='select-body__option'>
								{option.label}
							</option>
						))}
					</select>
					<FormHelperText>{languages[stateLanguage] && languages[stateLanguage].form2}</FormHelperText>
				</div>
				<TextField id="standard-basic"
									 helperText={languages[stateLanguage] && languages[stateLanguage].form3}
									 value={numberValue}
									 onChange={(e) => setNumberValue(e.target.value)}/>
			</div>
			<TextField
				id="outlined-multiline-static"
				multiline
				rows={4}
				variant="outlined"
				helperText={languages[stateLanguage] && languages[stateLanguage].form5}
				className='form__inputs-textarea'
				value={textValue}
				onChange={(e) => setTextValue(e.target.value)}/>
			<div className='container-form__right-block__buttons'>
				<div className='right-block__buttons-form'>
					<a
						href={`mailto:official@uds.energy?subject=${selectValue ? 'About Vacancie' + selectValue : ''}&body=${textValue ? textValue : ''} ${numberValue ? numberValue : ''} ${nameValue ? nameValue : ''}`}
						className='button-opacity'>{languages[stateLanguage] && languages[stateLanguage].button}</a>
				</div>
			</div>
		</form>
	);
}

export default FormComponent;
