import React from "react";

//Components
import AboutUs from './AboutUs'
import Language from "./Language";

//Style
import './AboutUsPage.css'


const OurProjectsPage = ({stateLanguage}) => {
    const [languages] = Language;

    return (
        <div className="body-page__projects">
            {languages[stateLanguage] && languages[stateLanguage].map((item, key) => (
                <AboutUs data={item} key={key}/>
            ))}
        </div>
    );
}

export default OurProjectsPage;
