import {ReactComponent as LogoSeparator} from '../../Images/logoSeparator.svg'
import {Link} from "react-router-dom";

const AboutUs = ({data}) => {
    return (
        <div className="about-item">
	        <div className='about-item__right'>
		        <div>
			        <p className='p2 headline__about-name'>{data.aboutUsHeadline}</p>
			        <h3 className='headline-about'>{data.headline}</h3>
			        <div className='about-item__left mobile-img'>
				        <img className='about-item__left-img' loading={'lazy'} src={data.img} alt="project-img"/>
			        </div>
			        {data.description.map((item, key) => (
				        <div key={key}>
					        {Array.isArray(item) ?
						        <ul className='list-vacan list-vacan__about'>{item.map((itemList, keyItem) => <li
							        key={keyItem}
							        className='p1 list-vacan__item'>{itemList}</li>)}</ul> :
						        <p className='p1 description-about' key={key}>{item}</p>}</div>
			        ))}
		        </div>
		        <div style={{marginTop: '24px'}}>
			        <Link to={'documents'} className='swiper-item__button button-opacity'>{data.documentsBtn}</Link>
		        </div>
		        <div className='body__separators'>
			        <p className='body__separators-line separators-line__left'></p>
			        <p className='body__separators-square'><LogoSeparator/></p>
			        <p className='body__separators-line separators-line__right'></p>
		        </div>
	        </div>
	        <div className='about-item__left descktop-img'>
		        <img className='about-item__left-img' loading={'lazy'} src={data.img} alt="project-img"/>
	        </div>
        </div>
    );
}

export default AboutUs;
