import Language from "./Language";
import Documents from "../Components/Documents";

const DocumentsPage = ({stateLanguage}) => {
	const [languages] = Language;

	return (
		<section className="body-page__projects main-style__section" style={{minHeight: 'calc(100dvh - 163px)'}}>
			<div className="container">
				<h1
					className='line-after__white main-style__headline'>{languages[stateLanguage] && languages[stateLanguage].documents}</h1>
				<Documents/>
			</div>
		</section>
	);
};

export default DocumentsPage;
