import ourProjects1 from "../../Images/ourProjects1.jpg";
import ourProjects2 from "../../Images/ourProjects2.jpeg";
import ourProjects3 from "../../Images/ourProjects3.png";

const Language = [{
    EN: [{
        headline: 'DataHub',
        description: ['DataHub is a highly loaded system for data exchange between participants of the energy market of Ukraine.','DataHub collects, stores and analyzes commercial accounting data from every meter in the country.','The system can predict what the consumption will be for each meter in the future; to transfer oblenergo data for billing to consumers on the basis of forecasts of their hourly consumption.'],
        img: ourProjects1,
    },
        {
            headline: 'Auction platform',
            description: ['An auction platform is needed to distribute the available capacity of Ukraine\'s interstate electricity networks between electricity importing / exporting companies.','This platform is necessary for joint auctions with neighboring countries of the European Union.'],
            img: ourProjects2,
        },
        {
            headline: 'Svarog',
            description: ['Svarog is a system of verification of resource schedules, which will help to control the balancing market and analyze the potential of market participants.'],
            img: ourProjects3,
        }],
    UA: [{
        headline: 'DataHub',
        description: ['DataHub — це високонавантажена система з обміну даними між учасниками енергетичного ринку України.','DataHub збирає, зберігає і аналізує дані комерційного обліку з кожного лічильника в країні.','Система може прогнозувати, яким буде споживання за кожним лічильником у майбутньому; передавати обленерго дані для виставлення рахунків споживачам на основі прогнозів їх погодинного споживання.'],
        img: ourProjects1,
    },
        {
            headline: 'Аукціонна платформа',
            description: ['Аукціонна платформа необхідна для розподілу доступної пропускної спроможності міждержавних електричних мереж України між компаніями імпортерами/експортерами електроенергії.','Ця платформа необхідна для проведення спільних аукціонів з суміжними країнами членами Європейського Союзу.'],
            img: ourProjects2,
        },
        {
            headline: 'Сварог',
            description: ['Сварог — це система верифікації ресурсних графіків, яка допоможе контролювати балансуючий ринок та аналізувати потенційні можливості учасників ринку.'],
            img: ourProjects3,
        }]
}]
export default Language;
