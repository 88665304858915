import React from "react";

//Components
import Language from "./Language";
import FormComponent from "../FormComponent/FormComponent";

//Material
import LinkedInIcon from '@material-ui/icons/LinkedIn';

//css
import './Form.css';

const Form = ({stateLanguage}) => {
    const [languages] = Language;

    return (
        <section className='body__form'>
            <div className='container'>
                <div className='container__form'>
                    <div className='container-form__left-block'>
                        <h2 className='form__main-headline'>{languages[stateLanguage] && languages[stateLanguage].mainHeadline}</h2>
                        <p className='p1 form__main-description'>{languages[stateLanguage] && languages[stateLanguage].mainDescription}</p>
                        <a href='https://www.linkedin.com/company/ukrenergo-digital-solutions/' className='linkedin-link__form' target="_blank" rel="noreferrer">
                            <LinkedInIcon/>
                        </a>
                    </div>
                    <FormComponent stateLanguage={stateLanguage}/>
                </div>
            </div>
        </section>
    );
}

export default Form;
