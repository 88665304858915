import React from "react";

//Components
import OneArticle from '../Components/OneArticle/OneArticle'
import Language from "../Components/NewsPage/Language";
import {Link, useHistory, useLocation} from "react-router-dom";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';


const OneNewsPage = ({stateLanguage}) => {
    const [languages] = Language;
    const history = useHistory();
    let {hash} = useLocation();

    return (
        <div className="body-page__article main-style__section">
            <div className="container">
                <Breadcrumbs aria-label="breadcrumb" separator={<ArrowForwardIosIcon/>} className='bread-crumbs'>
                    <div
                        className='bread-crumbs__back'
                        onClick={() => {
                            history.goBack();
                        }}
                    >
                        <ChevronLeftIcon/>
                    </div>
                    <Link color="inherit" to='/news'>
                        <p className='p2 bread-crumbs__link'>{languages[stateLanguage] && languages[stateLanguage].crumbsNews}</p>
                    </Link>
                    <p className='p2 bread-crumbs__current-page'>{languages[stateLanguage] && languages[stateLanguage].crumbsArticle + ` ${hash}`}</p>
                </Breadcrumbs>
                {languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
                    '#' + item.id === hash ? <OneArticle item={item} key={key}/> : null
                ))}
            </div>
        </div>
    );
}

export default OneNewsPage;