import React from "react";

import {Link} from "react-router-dom";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";

const OneNews = ({item}) => {
    return (
        <div className='container__news-page'>
            <div className='news-page__block-img'>
                <img src={item.img} loading={'lazy'} alt="img-news" className='block-img__img block-img__img-desktop'/>
            </div>
            <div className='news-page__description-block'>
                <div className='description-block__date-block'>
                    <p className='p3 date-block__date'>{item.date}</p>
                </div>
                <Link to={{pathname: '/article', hash: `#${item.id}` }}><h3 className='news-page__headline'>{item.headline}</h3></Link>
                <img src={item.img} loading={'lazy'} alt="img-news" className='block-img__img block-img__img-mobile'/>
                <p className='p3 news-page__description'>{item.description}</p>
                <div className='news-page__description-bottom'>
                    <p className='p3 news-page__hash'>{item.hash}</p>
                    <Link to={{pathname: '/article', hash: `#${item.id}` }} className='news-page__link'><ArrowForwardIosIcon/></Link>
                </div>
            </div>
        </div>
    );
}

export default OneNews;
