const Language = [{
    EN: {
        headlineRespos: 'Responsibilities:',
        headlinePlus: 'Will be a plus:',
        headlineSkills: 'Required skills:',
        items: [
            {
                button: 'Respond',
                active: true,
                headline: 'Python Developer • Middle / Senior',
                responsibilities: ['Develop internal information systems of the company.', 'Responsible for the design, development and effective operation of the software.', 'Carry out coverage by unit tests.', 'Design, develop and maintain RESTful web IP services.', 'Determine the need for code refactoring of existing information systems.', 'Perform database schema design.'],
                plus: 'Experience with Docker-compose, Kubernetes, Asyncio, FastAPI or Aiohttp.',
                skill: ['Higher Education.', 'Deep knowledge of Python programming language 3+ years.', 'Experience with web frameworks: Django, Django REST and Celery - 3+ years.', 'Knowledge of SQL, experience with relational and relational databases (Redis,PostgreSQL)', 'Experience in writing unit tests.', 'Knowledge and experience of working with queues RabbitMQ.', 'Knowledge and experience working with Docker.', 'Experience with version control system (git, github, GitLab)', 'Architecture design experience ІС.', 'Analytical mind, ability to work in a team, flexibility, high concentration on solving tasks', 'Average level of English language proficiency (correspondence and technical documentation).']
            },
            {
                button: 'Respond',
                active: false,
                headline: 'DevOps engineer  (inactive)',
                responsibilities: ['development and implementation of infrastructure for software development and services;', 'defining a strategy to strengthen the system and launch into production;', 'administration and configuration of servers, troubleshooting;', 'maintaining documentation for servers and systems;', 'search and management of new technologies to increase productivity;', 'ensuring the integrity and availability of information in data centers and public information systems;', 'debugging and support of work:', ['Web-servers; ', 'databases; ', 'clusters; ', 'monitoring tools; ', 'configuration optimization; ', 'code repositories; ', 'backup systems;'], 'implementation of software support at all stages of the life cycle.', 'ensuring the smooth operation of information systems.'],
                skill: ['knowledge and experience in creating high availability systems;', 'excellent knowledge and experience of setting up server operating systems Linux and Windows;', 'very good knowledge Microsoft Azure (Azure Kubernetes service, API management, application service, virtual machines, MSSQL servers and databases, networks, VPNs, etc.);', 'experience in building CI / CD;', 'knowledge and experience working with Istio, Envoy products;', 'knowledge of basic network services and protocols (TCP / IP, DNS, HTTP / HTTPS, FTP, SSH);', 'experience with version control systems (Git, svn, GitLab, TFS, github);', 'knowledge and experience with tools for continuous integration and delivery (Jenkins, TeamCity, GitLab, Bamboo, Github Actions, AWS CodePipeline);', 'knowledge and experience working with logging and tracing systems (ELK Stack, Graylog, Grafana, Jaeger);', 'experience in deploying and administering monitoring systems;', 'knowledge of virtualization systems;', 'ability to create simple and working solutions']
            },
            {
                button: 'Respond',
                active: false,
                headline: 'QA engineer (inactive)',
                responsibilities: ['testing of the integrated system according to certain criteria;', 'functional and non-functional testing;', 'compliance testing;', 'web application and API testing;', 'evaluation of the system according to the criteria of test coverage of system requirements, compliance with the expected results, the implementation of operation and maintenance;', 'recording test results in the system;', 'monitoring and analysis of the obtained results;', 'verification of fulfilled requirements;', 'teamwork with testers and programmers.'],
                skill: ['experience in software testing not less than 1 year;', 'clear understanding of the software life cycle;', 'excellent analytical skills;', 'functional / non-functional software testing;', 'ability to technically write a test plan (test strategy) for the release cycle / project;', 'clear understanding of test documentation at different stages of software development;', 'excellent ability to identify, prioritize, report a bug and the life cycle of a bug;', 'administration and support of a specialized test environment / tools / data;', 'ability to clearly prioritize test cases on software functionality;', 'experience in bug-tracking system (Jira);', 'clear understanding of client-server architecture and integration processes (REST / SOAP);', 'basic knowledge of SQL HTML / CSS, HTTP / HTTPS;', 'experience in Scrum / Kanban / Waterfall methodology;', 'Higher Education.']
            },
            {
                button: 'Respond',
                active: false,
                headline: 'Business analyst  (inactive)',
                responsibilities: ['збирання бізнес-вимог у ключових користувачів для автоматизації бізнес-процесів та створення нових внутрішніх та зовнішніх сервісів компанії (модернізація існуючого різноманітного спеціалізованого програмного забезпечення);', 'розробка технічних завдань, участь у реалізації проєктів з впровадження програмного забезпечення. (геоінформаційна система, технологічний портал, Power BI);', 'участь у функціональному тестуванні.'],
                skill: ['experience as a business analyst from one year;', 'experience in developing technical tasks for business process automation;', 'experience with non-relational databases;', 'understanding SDLC;', 'experience in developing project documentation (UML, Use Cases, Business Rules, Functional & Non-Functional Specifications, User Interface Design Specifications, User Stories, Backlogs);', 'Higher Education;', 'average level of English language proficiency (correspondence and technical documentation).']
            },
        ]

    },
    UA: {
        headlineRespos: 'Обов’язки:',
        headlinePlus: 'Буде плюсом:',
        headlineSkills: 'Необхідні навички:',
        items: [
            {
                button: 'Відгукнутися',
                active: true,
                headline: 'Python Developer • Middle / Senior',
                responsibilities: ['Розробляти внутрішні інформаційні системи компанії.', 'Відповідати за проєктування, розробку та ефективне функціонування програмного забезпечення.', 'Проводити покриття unit тестами.', 'Проєктувати, розробляти та підтримувати RESTful web сервіси ІС.', 'Визначати потребу в проведенні рефакторингу коду існуючих інформаційних систем.', 'Виконувати проєктування схеми баз даних.'],
                plus: 'Досвід роботи з Docker-compose, Kubernetes, Аsyncio, FastAPI або Aiohttp.',
                skill: ['Повна вища освіта.', 'Глибокі знання мови програмування Python 3+ років.', 'Досвід роботи із веб-фреймворками: Django, Django REST та Celery — 3+ років.', 'Знання SQL, досвід роботи з реляційними та нереляцийними базами даних (Redis,PostgreSQL)', 'Досвід написання unittest-ів.', 'Знання та досвід роботи з чергами RabbitMQ.', 'Знання та досвід роботи з Docker.', 'Досвід роботи з системою управління версіями (git, github, GitLab)', 'Досвід проєктування архітектури ІС.', 'Аналітичний склад розуму, вміння працювати в команді, гнучкість, висока концентрація на вирішення поставлених задач', 'Середній рівень володіння англійською мовою (листування і технічна документація).']
            },
            {
                button: 'Відгукнутися',
                active: false,
                headline: 'DevOps engineer  (неактивна)',
                responsibilities: ['розробка та впровадження інфраструктури для розробки ПО та сервісів;', 'визначення стратегії щодо зміцнення системи та запуску у виробництво;', 'адміністрування та налаштування серверів, усунення неполадок;', 'ведення документації для серверів і систем;', 'пошук та управління новими технологіями для збільшення продуктивності;', 'забезпечення цілісності та доступності інформації в центрах обробки даних і інформаційних системах загального доступу;', 'налагодження та підтримка роботи:', ['Web-серверів; ', 'баз даних; ', 'кластерів; ', 'інструментів моніторингу; ', 'оптимізації конфігурацій; ', 'репозиторії коду; ', 'систем бекапу;'], 'здійснення супроводу програмного забезпечення на всіх етапах життєвого циклу.', 'забезпечення безперебійної роботи інформаційних систем.'],
                skill: ['знання та досвід створення систем високої доступності;', 'відмінні знання і досвід налаштування серверних операційних систем Linux і Windows;', 'дуже добре знання Microsoft Azure (служба Azure Kubernetes, управління API, служба додатків, віртуальні машини, сервери і бази даних MSSQL, мережі, VPN і т. Д.);', 'досвід роботи в побудові CI / CD;', 'знання та досвід роботи з продуктами Istio, Envoy;', 'знання основних мережевих служб і протоколів, (TCP / IP, DNS, HTTP / HTTPS, FTP, SSH);', 'досвід роботи з системами контролю версій (Git, svn, GitLab, TFS, github);', 'знання та досвід роботи з інструментами безперервної інтеграції та доставки (Jenkins, TeamCity, GitLab, Bamboo, Github Actions, AWS CodePipeline);', 'знання та досвід роботи з системами логування і трасування (ELK Stack, Graylog, Grafana, Jaeger);', 'досвід розгортання і адміністрування систем моніторингу;', 'знання систем віртуалізації;', 'уміння створювати прості і робочі рішення']
            },
            {
                button: 'Відгукнутися',
                active: false,
                headline: 'QA engineer (неактивна)',
                responsibilities: ['тестування інтегрованої системи за певними критеріями;', 'функціональне і нефункціональне тестування;', 'тестування на відповідність;', 'тестування веб-додатків і API;', 'оцінювання системи за критеріями тестового покриття системних вимог, відповідності очікуваним результатам, здійснення функціонування і супроводу;', 'фіксування результатів тестів в системі;', 'моніторинг і аналіз отриманих результатів;', 'верифікація виконаних вимог;', 'спільна робота в команді з тестувальниками і програмістами.'],
                skill: ['досвід в тестуванні програмного забезпечення не менше 1 року;', 'чітке розуміння життєвого циклу програмного забезпечення;', 'відмінні аналітичні здібності;', 'функціональне/нефункціональне тестування програмного забезпечення;', 'вміння технічного написання тест-плану (тест стратегії) до релізного циклу/проєкту;', 'чітке розуміння щодо тестової документації на різних етапах створення програмного забезпечення;', 'відмінні здібності щодо визначення, пріоритезації, репорту багу та життєвого циклу багу;', 'адміністрування та підтримка спеціалізованого тестового середовища/ інструментів/даних;', 'вміння чітко пріоритезувати тест-кейси по функціоналу програмного забезпечення;', 'досвід роботи в баг-трекінговій системі (Jira);', 'чітке розуміння клієнт-серверної архітектури та інтеграційних процесів (REST/SOAP);', 'базові знання SQL HTML / CSS, HTTP / HTTPS;', 'досвід роботи по методології Scrum/Kanban/Waterfall;', 'вища освіта.']
            },
            {
                button: 'Відгукнутися',
                active: false,
                headline: 'Business analyst  (неактивна)',
                responsibilities: ['збирання бізнес-вимог у ключових користувачів для автоматизації бізнес-процесів та створення нових внутрішніх та зовнішніх сервісів компанії (модернізація існуючого різноманітного спеціалізованого програмного забезпечення);', 'розробка технічних завдань, участь у реалізації проєктів з впровадження програмного забезпечення. (геоінформаційна система, технологічний портал, Power BI);', 'участь у функціональному тестуванні.'],
                skill: ['досвід роботи бізнес-аналітиком від одного року;', 'досвід розробки технічних завдань на автоматизацію бізнес-процесів;', 'досвід роботи з нереляційними базами даних;', 'розуміння SDLC;', 'досвід у розробці проєктної документації (UML, Use Cases, Business Rules, Functional & Non-Functional Specifications, User Interface Design Specifications, User Stories, Backlogs);', 'повна вища освіта;', 'середній рівень володіння англійською мовою (листування і технічна документація).']
            },
        ]
    }
}]
export default Language;
