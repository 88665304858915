import {useEffect, useState} from "react";
import {BrowserRouter as Router} from "react-router-dom";
import Header from '../src/Components/Header/Header'
import Footer from '../src/Components/Footer/Footer'
import RootRouter from './RootRouter/RootRouter'
import './css/App.css';
import './css/Variables.css';

const App = () => {
	const [stateLanguage, setStateLanguage] = useState('')

	useEffect(() => {
		const language = localStorage.getItem("LANGUAGE");
		if (!language) {
			localStorage.setItem("LANGUAGE", 'UA')
			setStateLanguage('UA')
		} else {
			setStateLanguage(language)
		}
	}, []);

	const changeLanguage = (e) => {
		localStorage.removeItem("LANGUAGE")
		localStorage.setItem("LANGUAGE", e)
		setStateLanguage(e)
	}

	return (
		<Router>
			<div className="App">
				<Header changeLanguage={changeLanguage} stateLanguage={stateLanguage}/>
				<main className='main'>
					<RootRouter stateLanguage={stateLanguage}/>
				</main>
				<Footer stateLanguage={stateLanguage} changeLanguage={changeLanguage}/>
			</div>
		</Router>
	);
}

export default App;
