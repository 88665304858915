const Language = [{
    EN: {
        projects: 'Our projects',
        vacancies: 'Our vacancies:',
        news: 'News',
        documents: 'Documents',
    },
    UA: {
        projects: 'Наші проєкти',
        vacancies: 'Наші вакансії:',
        news: 'Новини',
        documents: 'Документи',
    },
}]
export default Language;
