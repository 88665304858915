import SwiperItem from './SwiperItem'
import Language from "./Language";
import background1 from '../../Images/backgroundOurVacancies1.jpg'
import background2 from '../../Images/backgroundOurVacancies2.jpg'
import background3 from '../../Images/backgroundOurVacancies3.jpg'
import background4 from '../../Images/backgroundOurVacancies4.jpg'
import background5 from '../../Images/backgroundOurVacancies5.jpg'
import {Swiper, SwiperSlide} from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, {Pagination} from 'swiper/core';
import './OurVacancies.css';

// install Swiper modules
SwiperCore.use([Pagination]);

const swiperData = [
	{
		headline: 'Devops engineer',
		background: background1,
		link: '#panel2',
	}, {
		headline: 'Business analyst',
		description: 'middle / senior',
		background: background2,
		link: '#panel4',
	}, {
		headline: 'Python Developer',
		description: 'middle / senior',
		background: background3,
		link: '#panel1',
	}, {
		headline: 'QA engineer',
		background: background4,
		link: '#panel3',
	}, {
		headline: 'Team lead',
		description: 'Python',
		background: background5,
		link: '#panel1',
	},
]

SwiperCore.use([Pagination]);

const OurVacancies = ({stateLanguage}) => {
	const [languages] = Language;


	return (
		<section className='body__our-vacancies'>
			<div className='container__our-vacancies'>
				<div className='container'>
					<h2
						className='our-vacancies__main-headline line-after__blue'>{languages[stateLanguage] && languages[stateLanguage].mainHeadline}</h2>
					<Swiper
						breakpoints={{
							360: {
								slidesPerView: 1,
							},
							510: {
								slidesPerView: 2,
							},
							768: {
								slidesPerView: 3,
							},
							1024: {
								slidesPerView: 4,
							},
							1200: {
								slidesPerView: 5,
							},
							1441: {
								slidesPerView: 5,
							}
						}}
						spaceBetween={16}
						centeredSlides={true}
						loop={true}
						pagination={{
							clickable: true
						}}
						className="body__swiper-vacancies">
						{swiperData.map((item, key) => (
							<SwiperSlide key={key}>
								<SwiperItem item={item}/>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
}

export default OurVacancies;
