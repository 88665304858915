const Language = [{
    EN: {
        headlineForm: 'Fill out the form',
        form1: 'Name, Last Name *',
        form2: 'What vacancy are you interested in? *',
        form3: 'Phone number *',
        form4: 'Email address *',
        form5: 'Additional Information',
        file: 'Add a CV file',
        button: 'SEND',
    },
    UA: {
        headlineForm: 'Заповніть форму',
        form1: 'ПІБ *',
        form2: 'Яка вакансія вас цікавить? *',
        form3: 'Номер телефону ',
        form4: 'Email адреса *',
        form5: 'Додаткова інформація',
        file: 'Додайте файл з CV',
        button: 'НАДІСЛАТИ',
    }
}]
export default Language;