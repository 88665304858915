const Language = [{
    EN: {
        aboutUs: 'ABOUT US',
        projects: 'PROJECTS',
        vacancies: 'VACANCIES',
        news: 'NEWS',
        street: 'Simona Petliura Street 27, Kyiv, Ukraine'
    },
    UA: {
        aboutUs: 'ПРО НАС',
        projects: 'ПРОЄКТИ',
        vacancies: 'ВАКАНСІЇ',
        news: 'НОВИНИ',
        street: 'Вулиця Симона Петлюри 27, Київ, Україна'
    },
}]
export default Language;