const Language = [{
    EN: {
        mainHeadline: 'What we offer',
        items: [{
            headline: 'Flexible schedule',
            description: 'We are looking for individual approaches to the issue of working time scheduling, so that our specialists dedicate more time to themselves and their families and loved ones.'
        }, {
            headline: 'Official employment ',
            description: 'Guarantee of fair and transparent cooperation in accordance with the current legislation of Ukraine is one of our most important values.'
        }, {
            headline: 'Remote work format',
            description: 'We are rapidly evolving with the modern IT industry, so we also do not waste time and effort on the way to the office'
        }, {
            headline: 'Competitive level of salary',
            description: 'Our methods of fighting for the best specialists are constant monitoring of the level of salaries and decent salary.'
        }, {
            headline: 'Long-term and stable cooperation',
            description: 'UDS is part of the country\'s largest energy company, so we provide professionals with all the conditions for professional growth'
        }, {
            headline: 'Paid vacation and sick leave',
            description: 'Qualitative and proper rest, as well as timely treatment - the key to productive and mutually beneficial cooperation.'
        }]
    },
    UA: {
        mainHeadline: 'Що ми пропонуємо',
        items: [{
            headline: 'Гнучкий графік',
            description: 'Ми шукаємо індивідуальні підходи до питання графіку, щоб наші фахівці присвячували більше часу собі та своїм близьким'
        }, {
            headline: 'Офіційне працевлаштування',
            description: 'Гарантія чесної та прозорої співпраці відповідно до чинного законодавства України – одна з наших найголовніших цінностей'
        }, {
            headline: 'Віддалений формат роботи',
            description: 'Ми стрімко еволюціонуємо разом із сучасною ІТ-галуззю, тому також не витрачаємо час та сили на дорогу до офісу'
        }, {
            headline: 'Конкурентоспроможний рівень оплати праці',
            description: 'Наші методи боротьби за найкращих фахівців  –  це постійний моніторинг рівня заробітної плати та гідна оплата праці'
        }, {
            headline: 'Стабільне і довгострокове співробітництво',
            description: 'UDS є частиною найбільшої енергетичної компанії країни, тому ми надаємо фахівцям всі умови для професійного зростання'
        }, {
            headline: 'Оплачувані відпустки',
            description: 'Якісний та повноцінний відпочинок, а також вчасне лікування – запорука продуктивної і взаємовигідної співпраці'
        }]
    }
}]
export default Language;
