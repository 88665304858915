import OurProjectsPage from "../Components/OurProjectsPage/OurProjectsPage";
import Language from "./Language";

const Projects = ({stateLanguage}) => {
	const [languages] = Language;

	return (
		<section className="body-page__projects main-style__section">
			<div className="container">
				<h1
					className='line-after__white main-style__headline'>{languages[stateLanguage] && languages[stateLanguage].projects}</h1>
				<OurProjectsPage stateLanguage={stateLanguage}/>
			</div>
		</section>
	);
}

export default Projects;
