import './documents.css'
import {baseRoute, getFiles} from "../../services/getAllFiles";
import {useEffect, useState} from "react";
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GetAppIcon from '@material-ui/icons/GetApp';

const Documents = () => {
	const [files, setFiles] = useState([]);

	useEffect(() => {
		getFiles().then(files => setFiles(files));
	}, [])

	return (
		<div className={'documents-card'}>
			{
				files.map(({name, file}, index) => (
					<a key={name + index} href={baseRoute + file} download={name} className={'documents-item'}>
						<InsertDriveFileIcon fontSize={'small'} className={'file'}/>
						<GetAppIcon fontSize={'small'} className={'download'}/>
						<p>{name}</p>
					</a>
				))
			}
		</div>
	);
};

export default Documents;
