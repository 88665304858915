import React from "react";

import {Link} from "react-router-dom";

const SwiperItem = ({item}) => {
    return (
        <div className='container__swiper-item'>
            <h1 className='swiper-item__headline' >{item.headline}</h1>
            <h1 className='swiper-item__headline line-after__white' >{item.headline2}</h1>
            {item.description.map((itemDescription,key) => (<p className='swiper-item__description p1' key={key}>{itemDescription}</p>))}
            <Link to="/about-us" className='swiper-item__button button-opacity'>{item.button}</Link>
        </div>
    );
}

export default SwiperItem;