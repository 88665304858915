import {Link} from "react-router-dom";

const SwiperItem = ({item}) => (
	<Link to={'/vacancies' + item.link} className='container__swiper-item-vacancies' style={{
		backgroundImage: `url(${item.background})`
	}}>
			<h4 className='swiper-item__headline-vacancies'>{item.headline}</h4>
			{item.description && <p className='swiper-item__description-vacancies p3'>{item.description}</p>}
	</Link>
)

export default SwiperItem;
