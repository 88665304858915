import SwiperItem from './SwiperItem'
import Language from "./Language";
import {Swiper} from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css"
import SwiperCore, {Pagination} from 'swiper/core';
import './BannerMainPage.css';

SwiperCore.use([Pagination]);

const BannerMainPage = ({stateLanguage}) => {
	const [languages] = Language;

	// const pagination = {
	// 	"clickable": true,
	// 	"renderBullet": function (index, className) {
	// 		return '<span class="' + className + '"></span>';
	// 	}
	// }

	return (
		<section className='body__banner main-background__img' id='top'>
			<div className='container'>
				<Swiper
					// pagination={pagination}
					className="body__swiper-banner">
					{languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
						// <SwiperSlide key={key}>
						<SwiperItem item={item} key={key}/>
						// </SwiperSlide>
					))}
				</Swiper>
			</div>
		</section>
	);
}

export default BannerMainPage;
