import AboutUsPage from "../Components/AboutUsPage/AboutUsPage";

const AboutUs = ({stateLanguage}) => (
	<section className="body-page__about-us main-style__section">
		<div className="container">
			<AboutUsPage stateLanguage={stateLanguage}/>
		</div>
	</section>
)

export default AboutUs;
