//Modules
import React from "react";
import {NavLink} from "react-router-dom";
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Language from './Language'

//img
import {ReactComponent as Logo} from '../../Images/logo.svg'

//css
import './Header.css';

const Header = ({changeLanguage,stateLanguage}) => {
    const [languages] = Language;


    const [stateBurger, setStateBurger] = React.useState({
        left: false,
    });

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setStateBurger({[anchor]: open });
    };

    const list = (anchor) => (
        <div
            role="presentation"
            onClick={toggleDrawer(anchor, false)}
            onKeyDown={toggleDrawer(anchor, false)}
            className='body__burger-menu'
        >
            <h2 className='header-menu__main-headline line-after__white'>Меню</h2>
            <ul className='burger-menu__main-list'>
                <li className='burger-menu__item'>
                    <NavLink activeClassName='burger-item-menu__link-active' className='item-menu__link-burger'
                             to="/about-us">{languages[stateLanguage]?.aboutUs}</NavLink>
                </li>
                <li className='burger-menu__item'>
                    <NavLink activeClassName='burger-item-menu__link-active' className='item-menu__link-burger'
                             to="/projects">{languages[stateLanguage]?.projects}</NavLink>
                </li>
                <li className='burger-menu__item'>
                    <NavLink activeClassName='burger-item-menu__link-active' className='item-menu__link-burger'
                             to="/vacancies#panel1">{languages[stateLanguage]?.vacancies}</NavLink>
                </li>
                {/*<li className='burger-menu__item'>*/}
                {/*    <NavLink activeClassName='burger-item-menu__link-active' className='item-menu__link-burger'*/}
                {/*             to="/news">{languages[stateLanguage]?.news}</NavLink>*/}
                {/*</li>*/}
            </ul>
            <ul className='list-language__items'>
                <li className={'burger-menu__item-language ' + (stateLanguage === 'UA' ? 'burger-menu__active-language' : '')} onClick={() => changeLanguage('UA')}>UA</li>
                <li className={'burger-menu__item-language ' + (stateLanguage === 'EN' ? 'burger-menu__active-language' : '')} onClick={() => changeLanguage('EN')}>EN</li>
            </ul>
        </div>
    );

    return (
        <header className="header">
            <div className="container">
                <div className='body-header'>
                    <div className='body-logo'>
                        <NavLink to="/"><Logo/></NavLink>
                    </div>
                    <div className='body-list'>
                        <ul className='main-list list-menu__items'>
                            <li className='item-menu'>
                                <NavLink activeClassName='item-menu__link-active' className='item-menu__link'
                                         to="/about-us">{languages[stateLanguage]?.aboutUs}</NavLink>
                            </li>
                            <li className='item-menu'>
                                <NavLink activeClassName='item-menu__link-active' className='item-menu__link'
                                         to="/projects">{languages[stateLanguage]?.projects}</NavLink>
                            </li>
                            <li className='item-menu'>
                                <NavLink activeClassName='item-menu__link-active' className='item-menu__link'
                                         to="/vacancies#panel1">{languages[stateLanguage]?.vacancies}</NavLink>
                            </li>
                            {/*<li className='item-menu'>*/}
                            {/*    <NavLink activeClassName='item-menu__link-active' className='item-menu__link'*/}
                            {/*             to="/news">{languages[stateLanguage]?.news}</NavLink>*/}
                            {/*</li>*/}
                        </ul>
                        <ul className='main-list list-language__items'>
                            <li className={'main-item item-language ' + (stateLanguage === 'UA' ? 'active-language' : '')} onClick={() => changeLanguage('UA')}>UA</li>
                            <li className={'main-item item-language ' + (stateLanguage === 'EN' ? 'active-language' : '')} onClick={() => changeLanguage('EN')}>EN</li>
                        </ul>
                    </div>
                    <div onClick={stateBurger.left === false ? toggleDrawer('left', true) : toggleDrawer('left', false)} className={'body-list__mobile ' + (stateBurger.left ? 'open__burger-menu' : '')}>
                        <span className='burger-menu__top'></span>
                        <span className='burger-menu__bottom'></span>
                    </div>
                    <SwipeableDrawer anchor={'left'} open={stateBurger['left']} onClose={toggleDrawer('left', false)} onOpen={() => false}>
                        {list('left')}
                    </SwipeableDrawer>
                </div>
            </div>
        </header>
    );
}

export default Header;
