import {ReactComponent as LogoSeparator} from "../../Images/logoSeparator.svg";

const Project = ({data}) => {
    return (
        <div className="project-item">

            <div className='project-item__left descktop-img'>
                <img loading={'lazy'} className='project-item__left-img' src={data.img} alt="project-img"/>
            </div>

            <div className='project-item__right'>
                <div>
                    <div className='body__project-status'>
                        <p className='p2 project-status__p'>In progress</p>
                    </div>
                    <h4 className='headline-project'>{data.headline}</h4>
                    <div className='project-item__left mobile-img'>
                        <img loading={'lazy'} className='project-item__left-img' src={data.img} alt="project-img"/>
                    </div>
                    {data.description.map((item, key) => (
                        <p className='p1 description-project' key={key}>{item}</p>
                    ))}
                </div>
                <div className='body__separators'>
                    <p className='body__separators-line separators-line__left'></p>
                    <p className='body__separators-square'><LogoSeparator /></p>
                    <p className='body__separators-line separators-line__right'></p>
                </div>
            </div>

        </div>
    );
}

export default Project;
