import Project from './Project'
import Language from "./Language";
import './OurProjectsPage.css'
const OurProjectsPage = ({stateLanguage}) => {
    const [languages] = Language;
    return (
        <div className="body-page__projects">
            {languages[stateLanguage] && languages[stateLanguage].map((item, key) => (
                <Project data={item} key={key}/>
            ))}
        </div>
    );
}
export default OurProjectsPage;
