import ourProjects1 from "../../Images/ourProjects1.jpg";
import ourProjects2 from "../../Images/ourProjects2.jpeg";
import ourProjects3 from "../../Images/ourProjects3.png";

const Language = [{
    EN: {
        mainHeadline: 'Our projects',
        items: [
            {
                headline: 'Energy Data hub',
                img: ourProjects1,
            }, {
                headline: 'Auction platform',
                img: ourProjects2,
            }, {
                headline: 'Svarog',
                img: ourProjects3,
            },
        ]
    },
    UA: {
        mainHeadline: 'Наші проєкти',
        items: [
            {
                headline: 'Energy Data hub',
                img: ourProjects1,
            }, {
                headline: 'АУКціонна платформа',
                img: ourProjects2,
            }, {
                headline: 'Сварог',
                img: ourProjects3,
            }
        ]
    }
}]
export default Language;
