import React from "react";
import {Link} from "react-router-dom";

const ProjectItem = ({item}) => {

    return (
        <Link to='/projects' className='our-projects__item'>
            <div className='our-projects__item-headline'>
                <h4>{item.headline}</h4>
            </div>
            <div className='our-projects__item-img'>
                <img src={item.img} loading={'lazy'} alt=""/>
            </div>
        </Link>
    );
}

export default ProjectItem;
