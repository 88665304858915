import React from "react";

//Components
import OfferItem from './OfferItem'
import Language from "./Language";

//css
import './Offer.css';

const Offer = ({stateLanguage}) => {
    const [languages] = Language;

    return (
        <section className='body__offer main-background__img'>
            <div className='container'>
                <h2 className='offer-items__main-headline line-after__white'>{languages[stateLanguage] && languages[stateLanguage].mainHeadline}</h2>
                <div className='container__offer-items'>
                    {languages[stateLanguage] && languages[stateLanguage].items.map((item, key) => (
                        <OfferItem item={item} key={key}/>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Offer;