import {useState} from "react";
import BannerMainPage from "../Components/BannerMainPage/BannerMainPage";
import OurProjects from "../Components/OurProjects/OurProjects";
import Offer from "../Components/Offer/Offer";
import OurVacancies from "../Components/OurVacancies/OurVacancies";
import Form from "../Components/Form/Form";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const MainPage = ({stateLanguage}) => {
	const [showScroll, setShowScroll] = useState(false)

	const checkScrollTop = () => {
		if (!showScroll && window.pageYOffset > 400) {
			setShowScroll(true)
		} else if (showScroll && window.pageYOffset <= 400) {
			setShowScroll(false)
		}
	};

	const scrollTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	};

	window.addEventListener('scroll', checkScrollTop)

	return (
		<>
			<BannerMainPage stateLanguage={stateLanguage}/>
			<OurProjects stateLanguage={stateLanguage}/>
			<Offer stateLanguage={stateLanguage}/>
			<OurVacancies stateLanguage={stateLanguage}/>
			{/*<NewsBlock  stateLanguage={stateLanguage}/>*/}
			<Form stateLanguage={stateLanguage}/>
			<div className='to-top' onClick={scrollTop} style={{display: showScroll ? 'flex' : 'none'}}>
				<ExpandLessIcon/>
			</div>
		</>
	);
};

export default MainPage;
